<template>
  <div class="body_bgi full border_box bgs_full">
    <big-screen-header headerName="防疫轨迹" />

    <div class="w_full h_full-50 flex_row_around p_10">
      <!-- 1 -->
      <div class="w_30_p h_full">
        <!-- 个人信息 -->
        <div class="w_full h_49_p">
          <div class="w_full h_50_p">
            <div class="w_30_p h_full m_0_auto">
              <img 
                class="w_full border_radius_10 cursor response_img" 
                :src='filePath + `${fangYiYaunDetailData.avatar}`' alt=""
              >
            </div>
          </div>

          <div class="w_full h_50_p color_fff pv_20 border_box">
            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">姓名：<span class="main_color">{{ fangYiYaunDetailData.name }}</span> </div>
              <div class="h_24 lh_24 mb_3">民族：<span class="main_color">{{ fangYiYaunDetailData.xxx || '蒙古族' }}</span> </div>
              <div class="h_24 lh_24 mb_3">性别：<span class="main_color">{{ fangYiYaunDetailData.xxxxx || '男' }}</span></div>
            </div>

            <div class="w_full pr_20">
              <div class="h_24 lh_24 mb_3 ellipsis_text_2">负责地区：<span class="main_color">{{ fangYiYaunDetailData.remark }}</span></div>
            </div>
            
            <div class="flex_row_between pr_20">
              <div class="h_24 lh_24 mb_3">
                电话：<span class="main_color fw_bold font_number ls_2">{{ formatPhone(fangYiYaunDetailData.phone) }}</span>
              </div>

              <!-- <div 
                class="h_24 lh_24 mb_3 ellipsis_text_2 cursor jian_bian_text_3 fw_bold" 
                @click="goToFangYiHuJiao()"
              >
                呼叫
              </div> -->
            </div>
          </div>
        </div>

        <div class="w_full h_49_p scroll_box overflow_y_auto">
          <pre class="w_full full scroll_box overflow_y_auto text_indent_2 color_fff text_1 wb_break_all">
            {{ fangYiYaunDetailData.content }}
          </pre>
        </div>
      </div>

      <!-- 2 -->
      <div class="w_70_p h_full-10 ph_10">
        <el-tabs class="full" v-model="activeName">
          <el-tab-pane class="full" label="免疫记录" name="first" style="height: 100% !important;">
            <div class="full">
              <div class="">
                <el-date-picker
                  v-model="signDate"
                  type="date"
                  placeholder="选择日期"
                  format="yyyy-MM-dd"
                  value-format="yyyy-MM-dd"
                  @change="selectSignDate"
                >
                </el-date-picker>
              </div>

              <!-- 显示 百度地图 -->
              <baidu-map 
                class="w_full h_full-50 mt_10" 
                :center="{lng: 118.845864, lat: 43.840107}" 
                :zoom="9" :max-zoom="16" :min-zoom="10"
                :scroll-wheel-zoom="true"
              >
                <!-- 缩放 控件 -->
                <bm-navigation anchor="BMAP_ANCHOR_BOTTOM_RIGHT"></bm-navigation>

                <!-- 地图类型 控件 -->
                <bm-map-type :map-types="['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP']" anchor="BMAP_ANCHOR_BOTTOM_LEFT"></bm-map-type>

                <!-- 描边 -->
                <bm-boundary name="赤峰市巴林右旗" :strokeWeight="2" strokeColor="blue" fillOpacity="0" />
                
                <bm-marker 
                  v-for="(item, index) in signList" :key="index" 
                  :position="item.point" 
                  :dragging="false" 
                  @click="openSignDetail(item)"
                >
                  <bm-info-window 
                    :width=400 
                    :show="signDetail.id == item.farmId" 
                    :position="item.point"
                    :content="signDetail.content" 
                    @close="signDetail.id = ''"
                  >
                    <div class="w_full flex_row_between">
                      <div class="w_50 h_50">
                        <img class="full" :src="filePath + signDetail.muHuPicture" alt="">
                      </div>

                      <div class="w_full-60">
                        <div>牧户姓名： {{ signDetail.farmName }}</div>
                        <!-- <div>地址： </div> -->
                        <div>防疫信息: 
                          <span v-for="(animal, animal_index) in signDetail.fangYiAnimal" :key="animal_index">
                            {{ animal.name }}: {{ animal.fangYiCount }},
                          </span>
                        </div>
                      </div>
                    </div>
                  </bm-info-window>
                </bm-marker>

                <!-- 跳点 -->
                <!-- <bm-marker v-for="(item, index) in points" :key="index" :position="item" :dragging="true" animation="BMAP_ANIMATION_BOUNCE">
                  <bm-label content="xxxxxxxxxxxxxx" :labelStyle="{color: 'red', fontSize : '24px'}" :offset="{width: -35, height: 30}"/>
                </bm-marker> -->

              </baidu-map>
            </div> 
          </el-tab-pane>

          <el-tab-pane label="免疫台账" name="second">
            <div class="w_full h_full relative">
              <div class="color_fff pb_10">{{ signDate }} 免疫总数：{{ fangYiList.length }} </div>

              <div class="w_full border_box flex_row_between mb_10 jian_bian_text_1 text_1 fw_bold ph_10 pb_7 border_b_1_s_ccc">
                <div class="flex_1 ">
                  <span class="">序号</span>
                </div>
                <div class="flex_2">
                  <span class="">免疫日期</span>
                </div>
                <div class="flex_1 ">
                  <span class="">牧户姓名</span>
                </div>
                <div class="flex_1 ">
                  <span class="">动物种类</span>
                </div>
                <div class="flex_1 ">
                  <span class="">存栏数</span>
                </div>
                <div class="flex_1 ">
                  <span class="">免疫数</span>
                </div>
                <div class="flex_2 ">
                  <span class="">所用疫苗名称</span>
                </div>
                <div class="flex_1 ">
                  <span class="">疫苗厂家</span>
                </div>
                <div class="flex_1 ">
                  <span class="">疫苗批号</span>
                </div>
              </div>

              <div class="w_full h_full-90 overflow_y_auto border_1_s_fff">
                <div
                  class="w_full h_50 lh_50 flex_row_between mb_5 color_fff text_1 ban_ma ph_10 align_center" 
                  :class="{active_bgc : activeRowIndex == index}"
                  v-for="(item, index) in fangYiList" :key="index"
                  @mouseover="activeRowIndex = index"
                  @mouseleave="activeRowIndex = null"
                >
                  <div class="flex_1 ">
                    <span class="">{{ index + 1 }}</span>
                  </div>
                  <div class="flex_2">
                    <span class="color_fff">{{ formatDate(item.createTime) }}</span> 
                  </div>
                  <div class="flex_1 ">
                    <span class="color_fff">{{ item.userName }}</span> 
                  </div>
                  <div class="flex_1 ">
                    <span class="color_fff">{{ item.animalName }}</span> 
                  </div>
                  <div class="flex_1 ">
                    <span class="color_fff ">{{ item.animalCount }}</span> 
                  </div>
                  <div class="flex_1 ">
                    <span class="color_fff ">{{ item.fangYiCount }}</span>
                  </div>
                  <div class="flex_2 ">
                    <span class="color_fff">{{ item.vaccinumName }}</span>
                  </div>
                  <div class="flex_1 ">
                    <span class="color_fff">{{ item.vaccinMaker }}</span>
                  </div>
                  <div class="flex_1 ">
                    <span class="color_fff">{{ item.vaccinBatchNumber }}</span>
                  </div>
                </div>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>  
    </div>
  </div>
</template>

<script>
  import mapCom from '@/components/map/fang-yi-map.vue'
  import { baseURL } from '@/const'

  import { 
    getAntiepidemicPersonInfo, getAntiepidemicPersonSignIn
  } from '@/api/fang-yi/fang-yi-yuan.js';

  import defMixin from '@/mixins/def.js'
  import defImgMixin from '@/mixins/def-img.js'
  import componentsMixin from '@/mixins/components.js'
  import imgMixin from '@/mixins/def-img.js'

  export default {
    name: 'FangYiGuiJi',
    mixins: [defMixin, defImgMixin, componentsMixin, imgMixin],
    components: {
      mapCom
    },
    data() {
      return {
        activeIndex: 0,
        activeRowIndex: null,
        activeName: 'first',
        userId: null,
        fangYiYaunDetailData: {},

        signDate: null,

        signDetail: {},
        signList: [],
        fangYiList: [],
      }
    },
    created() {
      
    },
    computed: {
      getNowDate() {
        var newDate = new Date();
        newDate.setTime(newDate.getTime());
        this.signDate = newDate.getFullYear()+"-" + (newDate.getMonth()+1) + "-" + newDate.getDate();
      },
    },
    mounted () {
      this.getNowDate

      let userId = this.$route.params.userId

      this.userId = userId

      // 获取 防疫员 基本信息
      this.getAntiepidemicPersonInfo(userId)

      // 获取 防疫员 签到信息
      this.getAntiepidemicPersonSignIn(userId, this.signDate)
    },
    destroyed() {

    },
    methods: {
      emitData() {
        
      },

      // 获取 防疫员详情
      async getAntiepidemicPersonInfo(userId) {
        let res = await getAntiepidemicPersonInfo(userId)
        // debugger
        try {
          if (res.code == 1000) {
            this.fangYiYaunDetailData = res.data
          }
        } catch(err) {
          console.log(err)
        }
      },

      // 获取 防疫员 签到信息
      async getAntiepidemicPersonSignIn(userId, date) {
        this.signList = []
        let res = await getAntiepidemicPersonSignIn(userId, date)
        // debugger
        try {
          if (res.code == 1000) {
            if (res.data.signItem.length) {
              res.data.signItem.map(item => {
                this.signList.push({ 
                  date: item.dateTime, 
                  point: { lng: item.longitude, lat: item.latitude},
                  pictures: item.pictures,
                  ownerId: item.ownerId,
                  ownerName: item.ownerName,
                  farmId: item.farmId,
                  farmName: item.farmName,
                })
              })
            }
          }

          this.fangYiList = res.data.recordsYi

          // 打开一个签到窗口
          this.openSignDetail(this.signList[0])
        } catch(err) {
          console.log(err)
        }
      },

      // 选择日期
      selectSignDate(date) {
        // 获取 防疫员 签到信息
        this.getAntiepidemicPersonSignIn(this.userId, date)
      },

      // 显示防疫员 签到 详情
      openSignDetail(item) {
        this.signDetail = {
          id: item.farmId,
          pictures: item.pictures,
          point: item.point,
          ownerId: item.ownerId,
          ownerName: item.ownerName,
          farmId: item.farmId,
          farmName: item.farmName,
        }

        let fangYiAnimal = []

        this.fangYiList.map(fangYiItem => {
          if (fangYiItem.userId == item.ownerId) {
            fangYiAnimal.push({ 
              name: fangYiItem.animalName, 
              fangYiCount: fangYiItem.fangYiCount,
            })

            if (fangYiItem.signPicture1) {
              this.signDetail.muHuPicture = fangYiItem.signPicture1
            }
          }
        })

        this.signDetail.fangYiAnimal = fangYiAnimal
      },
      
      clickHandler() {}
    }
  }
</script>

<style>

.BMap_cpyCtrl, .anchorBL > a { display: none !important; }

.el-tabs__item { color: #fff !important; }
.el-tabs__item.is-active { color: #409EFF !important; }

.el-tabs__content { height: calc(100% - 50px); }
.el-tab-pane { height: 100% !important; }
</style>

