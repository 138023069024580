<template>
  <div class="box" style="width: 100%; height: 100%; padding: 10px;"> 
    <iframe 
      class="full" 
      src="fang-yi-map.html" 
      frameborder="0" 
      style="width: 100%; height: 100%;" 
      ref="unity">
    </iframe>
  </div>
</template>

<script>
  import { getSignInList } from '@/api/fang-yi/fang-yi-yuan.js'

  export default {
    name: 'Map',
    data() {
      return {
        zoom: 6,
        center: [118.671389, 43.521016],
        map: null,
        userList: []
      }
    },
    created () {
      this.$nextTick(() => {
        // window.addEventListener('message', messageEvent => {
        //   let val = messageEvent.data
        //   console.log('接收 [fang-yi-map.html] 发送的参数 : ', val)

        //   this.$emit('showUser', true)

        //   if (val.userInfo && val.userInfo.name) {
        //     this.$emit('emitData', val)
        //   }
          
        //   // this.$router.push({
        //   //   path: "/bigscreen/dianBiaoJi"
        //   // })
        // })
      })
    },
    mounted() {
      // this.login()
      // this.getSignInList()
      // this.sendData()
    },
    watch: {
      userList(newVal) {
        console.log('newVal : ', newVal);
      }
    },
    methods: {
      async getSignInList() {
        let res = await getSignInList()
        try {
          if (res.code == 1000) {
            this.userList = res.data

            if (res.data.length) {
              this.$refs.unity.contentWindow.getMessageFromParent(res.data);
            }
          }
        } catch(err) {
          this.$message.error(err)
        }
      },

      sendData() {
        if (this.userList && this.userList.length) {
          this.$refs.unity.contentWindow.getMessageFromParent(this.userList);
        }
      },

      login() {
        let self = this;
        const subscribes = [];

        // // 订阅群发消息主题
        const subscribeAll = {};
        subscribeAll.topic = "/topic/position"

        subscribeAll.callback = ((val) => {
          if (val.body) {
            this.$refs.unity.contentWindow.getMessageFromParent(val.body);
          }
        })
        subscribes.push(subscribeAll)

        const params = {}
      
        params.url = websocketUrl
        
        params.listenerList = subscribes
      }
    }
  }
</script>

<style lang="less" scoped>
  .box {
    width: 100%;
    height: 100%;
  }
</style>